html,
body {
  height: 100vh;
  width: 100vw;
  background-color: black;
}

#page {
  border: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

button,
input,
canvas,
a {
  outline: none;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.calBtn {
  height: 20vh;
  /* background-color: #4232ff; */
}

.delBtn {
  height: 20vh;
  /* background-color: #ff3232; */
}

.drawBtn {
  height: 74vh;
}

.hoverbtn {
  transition: ease 0.1s;
}

.hoverbtn:active {
  box-shadow: none;
  transform: scale(0.98);
}

.menuButton {
  opacity: 0.65;
  transition: 0.3s ease;
}

.menuButton:hover {
  opacity: 1;
}

.menuOpen {
  opacity: 1;
  transition: 0.1s ease;
}

.menuClose {
  opacity: 0;
  transition: 0.1s ease;
}

.roomLink {
  background-color: #4232ff;
}

.clip-circle {
  clip-path: circle(32px at center);
  background-color: #fe813f;
}

.coffeeButton {
  background-color: #fe813f;
}

.twitterButton {
  background-color: #1da1f2;
}

/* width */
.scroller::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.scroller::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.5px grey;
  border-radius: 10px;
}

/* Handle */
.scroller::-webkit-scrollbar-thumb {
  transition: ease 0.5s;

  opacity: 0.2;
  border-radius: 10px;
  background: #fff;
}

/* Handle on hover */
.scroller::-webkit-scrollbar-thumb:hover {
  background: #5271ff;
}

.themeblue {
  background-color: #4232ff;
}

canvas {
  position: fixed;
}

.bar {
  height: 6vh;
}

.bg-main {
  background-color: #1e3040;
}

.imgCont {
  max-width: 50px;
  transition: 0.4s ease;
}

.imgCont:hover {
  max-width: 1000px;
}

.imgCont img {
  object-fit: cover;
  object-position: 0 0;
}

.prodHunt {
  background-color: #db542b;
}
